.unusual-flow {
    .hide {
        visibility: hidden
    }

    #product-header {
        // .content-item {
        //     // margin-left: 280px;
        // }

        // @media only screen and (max-width: 1670px) {
        //     .content-item {
        //         // margin-left: 190px;
        //     }
        // }

        .ant-btn {
            width: 18%;
        }
        // date
        .ant-picker {
            width: 160px;
        }

        // ticker
        .ant-input {
            width: 120px;
        }

        // select day/week
        .content-item-frequency {
            .select-button {
                width: 200px;
                margin-right: 0;
                
                span {
                    width: 33.3%;
                    cursor: pointer;
                }

                .ticker:not(.active), .weekly:not(.active) {
                    border-left: 1px solid rgba(0, 0, 0, 0.1);
                }
                
            }
        }

        .input-param {
            margin-left: 10px;
            // margin-right: 10px;
            width:60px;
        }
        .param {
            margin-top: 10px;
            .label {
                color: black;
                margin-left: 10px;
            }
        }
    }

    #product-content {
        margin-left: 30px;
        margin-top: 30px;

        &.searchTicker .empty {
            display: none;
        }

        &.searchDate .empty-ticker {
            display: none;
        }


        tr, th, td {
            border: 1px solid #ddd;
            padding: 10px;
        }

        .flow-table {
            margin-bottom: 20px;
            a {
                display: inline-block;
                padding: 10px;
            }

        }

        .flowData {
            margin: 30px 0;
            padding-left: 5px;
            // text-align: center;

            h2 {
                font-weight: bold;
                font-size: 18px;                
            }

            p {
                font-size:12px;
                font-style: italic;
            }
        }
        .chart {
            position:relative;

            .lenged-signal {
                position: absolute;
                top: 50px;
                left: 3px;
                font-size: 10px;
                color: #99989D;
            }

            .chart-title {
                font-size: 18px;

            }

        }       
    }
}

.dark #product-content {
    .ant-table {
        background: transparent;
        color: white;
    }

    .ant-table-thead > tr > th {
        background: transparent;
        color: white;
        font-weight: bold;
    }

    .ant-table-cell-row-hover {
        background: black;
    }

    .ant-table-pagination {
        // background-color: white;
        margin: 0;
        padding: 10px;
    }

    .ant-pagination-item:not(.ant-pagination-item-active) a {
        color: white;
    }

    h2, .ant-pagination-item-ellipsis, .ant-pagination-item-link {
        color: white;
    }
}
@primary-color: #6c4aff;