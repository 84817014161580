.products .large-container .content .qu-algo {
    .form .form-content {
        justify-content: end;

        .button {
            width: 18%;
        }
    }

    .table {
        margin-top: 20px;
    }

    h3 {
        font-weight:  800;
    }

    .img-perf, .img-table {
        margin-bottom: 30px;
    }

    .ant-tabs-tab h4 {
        color: grey;
    }
    .ant-tabs-tab-active h4 {
        font-weight: 800;
        // font-size: 15px;
        color: black;
        // text-decoration: underline;
    }

    .action-today {        
        width: 40%;
        padding: 20px;
        font-size: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        // white-space: pre-line;
        // text-align: center;
        
        .ant-table-thead {
            display: none;
        }
    }

    .echarts-for-react {
        left:80px;
    }

    #gaugeChart {
        position: relative;
        margin-top: 30px;
        margin-left: -70px;
        // padding-left: 300px;
        text-align: center;
    }

    #gaugeChartShort {
        color: white;
        position: absolute;
        top: 70px;
        left: 110px;
        font-weight: bold;
        font-size: 20px;
        transform: rotate(-45deg);
    }

    #gaugeChartLong {
        color: white;
        position: absolute;
        top: 70px;
        left: 320px;
        font-weight: bold;
        font-size: 20px;
        transform: rotate(45deg);
    }

    #gaugeChartText {
        margin-top:20px;
        // color: white;        

        .chartTutorialHeader {
            padding: 10px;
            margin: 0;
            border-radius: 10px 10px 0 0 ;
            // background-color: #393950;
            // color: white;
            font-weight: bold;
        }
        .chartTutorialContent {
            padding: 10px;
            // background-color: #282633;
            border-radius: 0 0 10px 10px;
        }
        .highlight {
            font-weight: bold;
            font-size: 18px;
        }
    }

    .ant-table-title {
        font-weight: bold;
        font-size: 18px;
    }

    .modeTicker {
        position: relative;
        width: 360px;
        border-radius: 20px;
        font-weight: bold;
        margin: 0;
        text-align: center;

        &.bull {
            background-color: #92D051;

            .modeTickerHeader {
                background-color: #6AB122;
            }
        }
        &.bear {
            background-color: #C45953;

            .modeTickerHeader {
                background-color: #B13A33;
            }
        }

        .modeTickerHeader {
            border-radius: 20px 20px 0 0;
            padding: 10px;
            font-size: 18px;
        }

        .modeTickerLong {
            padding: 15px 0 15px 0;
            font-size: 80px;
        }
        .modeTickerShort {
            font-size: 55px;
        }
    }

    .algo-tab {
        margin-top: 20px;
    }

    .quOrders {
        margin-top:20px;

        .ant-table-title {
            font-size: 28px;
            text-align: center;
            font-weight: bold;
            margin: 20px;
        }
    }

    .quCurrentHolding {
        margin-bottom: 20px;

        thead th {
            font-size: 16px;
            font-weight: bold;
        }

        .ant-table-cell {
            vertical-align: top;
        }

        .ant-table-cell.start {
            border-radius: 10px 0 0 0;
        }

        .ant-table-cell.end {
            border-radius: 0 10px 0 0;
        }
    }

    .return-section {
        h2 {
            margin: 20px;
            font-size: 28px;
            text-align: center;
            font-weight: bold;
            // color: white;
        }

        .ant-table {
            background-color: transparent;
            // color: white;
            font-size: 16px;
            // background: transparent;

            td {
                width: 130px;
                text-align: center;
            }
            th {
                // color: white;
                padding-top:5px;
                // background-color: #242434;
                text-align: center;
                // border-top: 1px solid gray;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
        }
    }
}

// dark theme
.ant-layout.dark .qu-algo {
    .ant-pagination {
        
        .ant-pagination-item a, .ant-pagination-item-link, .ant-pagination-item-ellipsis {
            color: #6c4aff;
        }
    }

    .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-content-holder {
        border: 3px solid  #535373;
        padding: 10px 10px 20px;

    }
    .ant-tabs-top > .ant-tabs-nav::before {        
        border-bottom: 1px solid  #535373;
        position: absolute;
        z-index: 10000;
    }
    .ant-tabs-tab {
        background-color: #393950;
        border: transparent;

        &.ant-tabs-tab-active {
            background-color: #535373;
            h4 {
                color: white;
            }
            
        }
    }

    .quOrders {
        .ant-table {
            background-color: #1B1923;
            color: white;

            .ant-table-thead {
                padding: 10px;
                margin: 0;

                > tr > th {
                    background-color: #393950;
                    color: white;
                    font-weight: bold;
                    border: none;
                }

                .ant-table-cell.opened {
                    border-radius: 10px 0 0 0;
                }
                .ant-table-cell.percent {
                    border-radius: 0 10px 0 0;
                }
            }

            tbody tr:hover {
                background-color: #242434;
                color: black;
            }

            .ant-table-row:nth-child(even) {
                background-color: #393950;
              }

            tr > td {
                border: transparent;
            }
        }        
    }

    #gaugeChartText {
        color: white;        

        .chartTutorialHeader {
            background-color: #393950;
            color: white;
        }
        .chartTutorialContent {
            background-color: #282633;
        }
    }

    .return-section {
        h2 {
            color: white;
        }

        .ant-table {
            background-color: transparent;
            color: white;

            th {
                color: white;
                background-color: #242434;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
        }
    }

    #gaugeChartText {       
        color: white;
    }

    .modeTicker {
        color: white;        
    }
}
@primary-color: #6c4aff;