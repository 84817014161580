.comments-block-content {
    .container {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
    }

    .column {
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: column;
    }

   .row {
        width: 387px;
        border-radius: 6px;
        border: 1px solid #48474F;
        margin-bottom: 20px;
        padding: 60px 40px 40px;
        position: relative;

        img {
            position: absolute;
            top: 20px;
        }

        .comment {
            overflow: hidden;
            position: relative;
        }

        p {
            margin: 0 0 5px;
            font-size: 16px;
            line-height: 22px;
        }

        .commentator {
            margin-top: 20px;
            font-size: 18px;
        }

        .position {
            font-size: 14px;
            margin-top: 5px;
            color: rgba(255, 255, 255, 0.65);
        }
   }
}
@primary-color: #6c4aff;