.about-us {
    background-color: #1b1923;
    
    .banner {
        height: 680px;
        background-color: #1F2220;
        background-image:url('../../asset/aboutUs/banner.png');
        background-position: center top;
        background-size: 1960px auto;
        background-repeat: no-repeat;

        .container {
            .title {
                padding-top: 150px;
                font-size: 72px;
                font-weight: 600;
            }

            .description {
                width: 510px;
                line-height: 22px;
                font-size: 16px;
                margin-top: 30px;

                .text {
                    margin-bottom: 20px;
                    word-wrap: break-word;
                }
            }
        }
    }

    .block {
        padding-top: 95px;

        .title {
            text-align: left;
            font-size: 32px;
            font-weight: 600;
            line-height: 45px;
        }

        .text {
            margin-top: 20px;
            font-size: 16px;
        }

        .qrCode-btn {
            cursor: pointer;
            margin-left: 5px;
        }
    }
}

.ant-popover-content {  
    .ant-popover-arrow-content {
        background-color: #0d0da5;
    }

    .ant-popover-inner-content {
        padding: 7px;
        background-color: #818197;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
    }

    .qrCode-img {
        width: 136px;
    }
}

@primary-color: #6c4aff;