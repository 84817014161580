.success {
    img {
        width: 64px;
    }

    .text {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.85);
    }

    .back-btn {
        width: 160px;
        height: 52px;
        border-radius: 4px;
        border: 1px solid #6C4AFF;
        color: #6C4AFF;
        font-size: 18px;
        margin-top: 70px;
        
        &:hover {
            opacity: 0.8;
            border: 1px solid rgba(108, 74, 255, 0.8);
        }
    }
}
@primary-color: #6c4aff;