.service-agreement {
    .title {
        padding: 30px 0 20px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: rgba(255, 255, 255, 0.85);
        font-weight: 500;
    }

    .text,
    .sub-text {
        margin-bottom: 20px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
    }

    .sub-text {
        text-indent: 30px;
    }
}
@primary-color: #6c4aff;