.products-block-content {
    .badge-option-trade {
        position: absolute;
        z-index: 1000;
        left: 4px;
        top: 160px;
    }

    .badge-unusalflow {
        position: absolute;
        z-index: 1000;
        left: 208px;
        top: 160px;
    }
    .menu {
        margin: 0 -12px;
    }
    
    .icon {
        display: inline-block;
        width: 180px;
        min-height: 144px;
        border-radius: 6px;
        text-align: center;
        margin: 0 12px;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        .icon-img {
            width: 60px;
            margin: 20px auto 10px;
            transition: all .3s ease-in-out;
        }

        .icon-line {
            z-index: 2;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 4px;
            border-radius: 25px;
            width: 0;
        }
    }
    
    .active {
        transition: all 1s;
        background: #242434;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);

        .icon-img {
            width: 60px;
            margin: 15px auto 10px;
        }

        .icon-line-active {
            z-index: 2;
            position: absolute;
            left: 0;
            bottom: 0;
            height: 4px;
            border-radius: 25px;
            background: #6C4AFF;
            transition: width 7s;
            width: 100%;
        }
    }

    .content {
        overflow: hidden;
        margin-top: 60px;
        height: 360px;
        position: relative;

        .content-text {
            float: left;
            width: 560px;
            padding-right: 41px;

            .spot {
                color: #836E63;
                margin-right: 5px;
            }

            .title {
                font-size: 16px;
                font-weight: 600; 
                color: #FFFFFF;
                margin: 24px 0 5px;
                text-align: left;
            }

            .description {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.65);
                margin: 0 13px;
                line-height: 20px;
            }

            .button {
                padding: 0 25px;
                height: 48px;
                border-radius: 4px;
                border: 1px solid #6C4AFF;
                font-size: 16px;
                font-weight: 600;
                color: #FFFFFF;
                background: none;
                position: absolute;
                bottom: 0;
            }
        }

        .content-img {
            float: right;
            width: 640px;
            height: 360px;
            border: 1px solid #6C4AFF;
            border-radius: 4px;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
            }
            
            .enlarge {
                position: absolute;
                width: 48px;
                height: 48px;
                bottom: 20px;
                right: 20px;
                cursor: pointer;
                background-image:url('../../../../asset/home/products/enlarge.png');
                background-size: 100%;

                &:hover {
                    background-image:url('../../../../asset/home/products/enlarge-active.png');
                }
            }
        }
    }
}

.img-modal {
    img {
        width: 100%;
        height: 100%;
    }
}

.img-modal {
    .ant-modal-content,img {
        background: #1B1923;
        border-radius: 8px;
    }
    .ant-modal-close { 
        color: rgba(255 ,255 ,255 ,0.65);
    }
    .ant-modal-body {
        padding: 0;
        min-height: 675px;
    }
}

@primary-color: #6c4aff;