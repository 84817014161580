.not-found {
    .banner {
        height: 760px;
        background-color: #1F2220;
        background-image:url('../../asset/exception/banner.png');
        background-position: center top;
        background-size: 1960px auto;
        background-repeat: no-repeat;

        .container {
            .title {
                padding-top: 270px;
                font-size: 72px;
                font-weight: 600;
            }

            .text {
                font-size: 18px;
            }

            .button {
                width: 164px;
                height: 60px;
                background: #6C4AFF;
                border-radius: 4px;
                font-size: 18px;
                line-height: 60px;
                color: #FFFFFF;
                border: none;
                padding: 0;
                margin-top: 145px;
            }
        }
    }
}

@primary-color: #6c4aff;