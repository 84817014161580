.products .large-container .content .market-history {
    .form .form-content {
        justify-content: end;

        .button {
            width: 18%;
        }
    }

    .label {
        margin-right: 10px;
    }

    .ant-picker {
        width: 200px;
    }

    .table {
        margin-top: 20px;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .table-title {
            margin-bottom: 0;
        }

        .table-input {
            display: flex;
            align-items: center;

            span {
                font-size: 16px;
                margin-right: 7px;
                font-weight: 600;
                color: rgba(255, 255, 255, 0.85);
            }

            .ant-input {
                width: 200px;
                height: 36px;
                background: unset;
                border: 1px solid rgba(255, 255, 255, 0.25);
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
}
@primary-color: #6c4aff;