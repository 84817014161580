.light {
    .overview {
        background-color: #EFEFF6;
        background: #EFEFF6;

        .large-container {
            // border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFFFFF;

            .user-info {                
                .level {         
                    color: rgba(0, 0, 0, 0.85);

                    img {
                        opacity: 0.7;
                    }
                }
        
                .upgrade {
                    border-left: 1px solid rgba(0, 0, 0, 0.15);
                }
            }

            .item {
                border: 1px solid rgba(0, 0, 0, 0.15);

                .img {
                    overflow: hidden;
                    // height: 228px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    font-size: 0;
                    background: #EFEFF6;

                    img {
                        width: 100%;
                    }
                }
                

                .title {
                    color: rgba(0, 0, 0, 0.85);

                    .anticon {
                        margin-right: 3px;
        
                        g {
                            stroke: rgba(0, 0, 0, 0.85);
                        }
                    }
                }

                .description {
                    color: rgba(0, 0, 0, 0.65);
                }

                &:hover {
                    background: #fff;
                    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
                    border: 1px solid #fff;
                    border-top: 5px solid #6C4AFF;
                    padding-top: 11px;
                }
            }
        }
    }
}

.overview {
    overflow: hidden;
    min-width: auto;
    max-width: 100%;
    margin: 0 auto;
}

.overview .large-container {
    // margin: 40px 20px;
    // border-radius: 6px;
    // border: 1px solid rgba(255, 255, 255, 0.2);
    // padding: 24px;
    margin: 0;
    border: none !important;

    .user-info {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: end;
        margin-bottom: 24px;
        
        .level {         
            font-size: 24px;
            font-weight: 600;
            margin-right: 16px;
            color: rgba(255, 255, 255, 0.85);
            display: flex;
            align-items: center;

            img {
                width: 30px;
                margin-right: 6px;
            }
        }

        .upgrade {
            border-left: 1px solid rgba(255, 255, 255, 0.15);

            a {
                font-size: 16px;
                margin-left: 16px;
            }
        }
    }

    .item {
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 15px 15px 20px;
        cursor: pointer;
        height: 100%;

        .img img {
            width: 100%;
            min-height: 270px;
            border: 1px solid rgba(255, 255, 255, 0.15);
        }
        
        .title {
            font-size: 18px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            margin: 15px 0;
            display: flex;
            align-items: center;

            .anticon {
                margin-right: 3px;

                g {
                    stroke: rgba(255, 255, 255, 0.85); 
                }
            }
        }

        .description {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.65);
            line-height: 21px;
        }

        &:hover {
            background: #242434;
            box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
            border: 1px solid #242434;
            border-top: 5px solid #6C4AFF;
            padding-top: 11px;

            .title {
                font-weight: 600;
            }
        }
    }
}
@primary-color: #6c4aff;