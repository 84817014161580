.capital-flow {
    .content-item {
        width: 41%;
        padding-right: 10px;
    }

    .ant-input {
        width: calc(100% - 80px);
    }

    .ant-picker {
        width: calc(100% - 50px);
    }

    .button {
        width: 18%;
    }

    .table {
        margin-top: 16px;
    }
}
@primary-color: #6c4aff;