.market {
    .content-item {
        display: flex;
        align-items: center;
        padding-right: 10px;
        width: 46%;
    }

    .select-button {
        width: calc(33% - 25px);
    }

    .ant-picker {
        width: calc(100% - 50px);
    }

    .button {
        width: 21%;
    }

    .purple {
        cursor: pointer;
    }

    .table {
        margin-top: 16px;
    }
}
@primary-color: #6c4aff;