.planHighlight-block {
    position: absolute;
    width: 1200px;
    background: #242434;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    top: 800px;
    overflow: hidden;
    padding: 26px 0;
    height: 128px;
    transition: all ease-in 0.2s 0s;

    .news-content {
        display: flex;
    }

    .new-item:nth-child(2)::before,
    .new-item:nth-child(3)::before {
        content: '';
        height: 75px;
        width: 1px;
        background-color: #4F4F5C;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }

    .new-item {
        flex: 1;
        padding: 0 32px;
        font-size: 16px;
        position: relative;
        height: 75px;

        h1 {
            font-size: 18px;
            font-weight: bold;
            color: #fff;
        }

        .icon {
            width: 20px;
            margin-right: 10px;
        }

        .description {
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: 22px;
        }
        
        .tag {
            position: absolute;
            bottom: 0;
            display: inline-block;
            font-size: 12px;
            padding: 1px 5px;
            background: #6C4AFF;
        }
    }

    .view-more {
        display: none;
        text-align: center;
        font-size: 16px;
        margin-top: 15px;

        a {
            color: #fff;
            
            span {
                margin-left: 5px;
            }

            &:hover {
                color: #6C4AFF;
            }
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
        opacity: 0; /*初始状态 透明度为0*/
        }
        20%{
            opacity: .2;
        }
        50% {
            opacity: .5; /*中间状态 透明度为0.5*/
        }
        70%{
            opacity: .7;
        }
        100% {
            opacity: 1; /*结尾状态 透明度为1*/
        }
    }
   
    &:hover {
        height: 168px;

        .view-more {
            display: block;
            -webkit-animation-name: fadeIn; /*动画名称*/
            -webkit-animation-duration: 0.5s; /*动画持续时间*/
            -webkit-animation-iteration-count: 1; /*动画次数*/
            -webkit-animation-delay: 0s; /*延迟时间*/
        }
    }
}
@primary-color: #6c4aff;