.light .products {
    background-color: #EFEFF6;
    background: #EFEFF6;
    color: rgba(0, 0, 0, 0.85);

    .large-container {
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;

        .menu .ant-menu {
            background: rgba(239, 239, 246, 0.45);;
            color: rgba(0, 0, 0, 0.85);
            border-right: 1px solid rgba(0, 0, 0, 0.15);

            g {
                stroke: rgba(0, 0, 0, 0.85);
            }

            .ant-menu-item {
                &:hover {
                    color: rgba(0, 0, 0, 0.85);
                    background-color: rgba(108, 74, 255, 0.05);
                }
            }

            .ant-menu-item-selected {
                background-color: rgba(108, 74, 255, 0.05);;
                color: rgba(0, 0, 0, 0.85);
            }
        }

        .content .chart .chart-description { 
            color: rgba(0, 0, 0, 0.65);
        }   
    }

    .form {
        background: #FFFFFF;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

        .ant-input:focus,
        .ant-picker-focused, 
        .ant-picker:hover,
        .ant-input:hover {
            box-shadow: none; 
            border: 1px solid rgba(0, 0, 0, 0.15);
        }

        .select-button {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .update-time {  
        color: rgba(0, 0, 0, 0.65);
    }

    .table-item {
        border: 1px solid rgba(0, 0, 0, 0.15);

        .ant-table-thead > tr > th {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);;
            background: #f8f8fe;
        }
    }
}

.dark .products .large-container {
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.85);

    .menu .ant-menu {
        background: rgba(17, 15, 27, 0.45);
        color: rgba(255, 255, 255, 0.85);
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        g {
            stroke: rgba(255, 255, 255, 0.85);
        }

        .ant-menu-item {
            &:hover {
                color: rgba(255, 255, 255, 0.85);
                background-color: #242434;
            }
        }

        .ant-menu-item-selected {
            background-color: #242434;
            color: rgba(255, 255, 255, 0.85);
        }
    }

    .form {
        background: #242434;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.4);

        svg {
            color: rgba(255, 255, 255, 0.85);
        }

        .ant-input {
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: rgba(255, 255, 255, 0.85);

            .ant-picker-input > input {
                color: rgba(255, 255, 255, 0.85); 
            }

            .ant-picker-input:hover {
                svg {
                    background-color: #242434;
                }
            }
        }

        .ant-input:focus,
        .ant-picker-focused, 
        .ant-picker:hover,
        .ant-input:hover {
            box-shadow: none;
            border: 1px solid rgba(255, 255, 255, 0.2);
        }

        .select-button {
            border: 1px solid rgba(255, 255, 255, 0.25);
        }
    }

    .update-time {  
        color: rgba(255, 255, 255, 0.65);
    }

    // .ant-picker {
    //     border: 1px solid rgba(255, 255, 255, 0.25);
    //     color: rgba(255, 255, 255, 0.85);

    //     .ant-picker-input > input {
    //         color: rgba(255, 255, 255, 0.85); 
    //     }

    //     svg {
    //         color: rgba(255, 255, 255, 0.85);
    //     }

    //     .ant-picker-input:hover {
    //         svg {
    //             background-color: #242434;
    //         }
    //     }
    // }

    // .ant-picker-dropdown {
    //     .ant-picker-panel-container {
    //         background: #242434;
    //         box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

    //         .ant-picker-panel {
    //             border: none;
    //         }

    //         .ant-picker-header {
    //             border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    //             button {
    //                 color: rgba(255, 255, 255, 0.25);
    //             }
    //             .ant-picker-header-view button {
    //                 color: rgba(255, 255, 255, 0.85);
    //             }
    //         }
    //         .ant-picker-footer {
    //             border-top: 1px solid rgba(255, 255, 255, 0.25);
    //         }
    //         .ant-picker-cell {
    //             color: rgba(255, 255, 255, 0.25);
    //         }
    //         .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    //         .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    //             background: rgba(108, 74, 255, 0.50);
    //         }
    //         .ant-picker-content th,
    //         .ant-picker-cell-in-view {
    //             color: rgba(255, 255, 255, 0.85);
    //         }
    //     }
    // }

    .table-item {
        border: 1px solid rgba(255, 255, 255, 0.15);

        .ant-table-thead > tr > th {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            background: #242434;
            color: rgba(255, 255, 255, 0.85);
        }
        .ant-table-tbody > tr > td {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        }

        .ant-table-content {
            background: unset;
        }

        .ant-table-tbody {
            background: #1B1A24;
            color: rgba(255, 255, 255, 0.85);

            .ant-empty-description {
                color: rgba(255, 255, 255, 0.85);
            }

            .ant-empty-img-simple {
                stroke: rgba(255, 255, 255, 0.85);

                .ant-empty-img-simple-path {
                    fill: #242434;
                }
                .ant-empty-img-simple-ellipse {
                    fill: #242434;
                }
            }
            
            tr.ant-table-row:hover > td, tr > td.ant-table-cell-row-hover,.ant-table-cell-row-hover {
                background: #242434;
            }

            td.ant-table-column-sort {
                background: unset;
            }
        }

        .ant-table-placeholder:hover > td {
            background: #1B1A24;
        }

       
        .ant-pagination{
            .ant-pagination-item a,
            .ant-pagination-item-ellipsis,
            .anticon  {
                color: rgba(255, 255, 255, 0.65);

                &:hover {
                    color: #6c4aff;;
                }
            }

            .ant-pagination-item-active{
                background: #6c4aff;

                a {
                    color: #fff;
                }
            }

            .ant-select-selector {
                background-color: unset;
                color: rgba(255, 255, 255, 0.65);
            }

            .ant-select-dropdown {
                background: #242434;

                .ant-select-item-option-content{
                    color: rgba(255, 255, 255, 0.65);
                }

                .ant-select-item-option-selected {
                    background: rgba(108, 74, 255, 0.5);
                }

                .ant-select-item-option-active {
                    background: #6c4aff;
                }
            }
        }
    }

    .chart {
        .chart-description { 
            color: rgba(255, 255, 255, 0.65);
        }

        .ant-select{ 
            .ant-select-selector {
                border: 1px solid rgba(255, 255, 255, 0.25);
                color: rgba(255, 255, 255, 0.85);
            }
            .ant-select-arrow {
                color: rgba(255, 255, 255, 0.25);
            }
        }

        .ant-select-dropdown {
            background: #242434;

            .ant-select-item-option-content{
                color: rgba(255, 255, 255, 0.65);
            }

            .ant-select-item-option-selected {
                background: rgba(108, 74, 255, 0.5);
            }

            .ant-select-item-option-active {
                background: #6c4aff;
            }
        }
    }
}


.products {
    overflow: hidden;
    min-width: 1440px;
    max-width: 1800px;
    margin: 0 auto;

    .large-container {
        border-radius: 6px;
        padding: 0;

        .menu .ant-menu {
            font-size: 16px;
            padding: 30px 0;
            height: 100%;

            .ant-menu-item {
                margin: 2px 0;
                padding: 0 30px;
                height: 58px;
                line-height: 58px;
                box-shadow: unset;
                display: flex;
                align-items: center;

                &:hover {
                    font-weight: 600;
                }

                &:active {
                    background: unset;
                }
            }

            .ant-menu-item-selected {
                font-weight: 600;

                &::after {
                    transform: scaleY(1);
                    opacity: 1;
                    border-right: 3px solid #6C4AFF;
                }
            }
        }

        .content {
            padding: 40px 20px;
            min-height: 200vh;

            .purple {
                color: #6C4AFF;
            }

            .form {
                height: 125px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 40px;
                border-radius: 6px;

                .form-title {
                    font-size: 32px;
                    font-weight: 800;
                }

                .form-subTitle {
                    font-size: 16px;
                    color:#acaab7
                }

                .form-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                .form-container-otter {
                    display: column;
                    width: 100%;
                }

                .form-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    
                    .label {
                        font-size: 16px;
                        margin-right: 5px;
                    }

                    .ant-input {
                        border-radius: 2px;
                        height: 36px;
                        line-height: 36px;
                        background-color: unset;
                    }

                    .button {
                        height: 42px;
                        background: #6C4AFF;
                        border-radius: 2px;
                        color: #fff;
                        font-size: 18px;
                        font-size: 600;
                        border: unset;
                    }

                    .select-button {
                        border-radius: 2px;
                        line-height: 34px;
                        width: calc(33% - 25px);
                        margin-right: 25px;
                
                        span {
                            display: inline-block;
                            width: 50%;
                            text-align: center;
                            position: relative;
                        }

                        span:first-child {
                            border-radius: 2px 0 0 2px;
                        }

                        span:last-child {
                            border-radius: 0 2px 2px 0;
                        }

                        .active {
                            background: rgba(108, 74, 255, 0.15);
                            border: 1px solid #6C4AFF;
                            font-weight: 800;
                        }
                    }
                }
            }

            .update-time {
                margin-top: 30px;
                font-size: 14px;
            }

            .table-item {
                padding: 20px;
                border-radius: 4px;
                height: 100%;

                .ant-table {
                    background: unset;
                }

                .table-title {
                    font-size: 18px;
                    font-weight: 800;
                    margin-bottom: 20px;
                }

                .ant-table-thead > tr > th {
                    height: 42px;
                    font-size: 800;
                    border-radius: 0px;
        
                    &::before {
                        content: unset;
                    }
                }

                .ant-table-empty tr > td:last-child {
                    border-bottom: none;
                }

                .ant-table-column-sorters {
                    justify-content: unset;

                    .ant-table-column-title {
                        flex: unset;
                    }
                }
            }

            .ant-picker {
                border-radius: 2px;
                height: 36px;
                line-height: 36px;
                background-color: unset;
            }

            .chart{
                margin-top: 40px;
                position: relative;
        
                .chart-title {
                    font-size: 18px;
                    font-weight: 800;
                    margin-bottom: 10px;
                }

                .chart-description {
                    font-size: 12px;    
                    color: rgba(255, 255, 255, 0.65);
                    position: absolute;
                    width: 800px;
                    text-indent: -5px;
                    padding-left: 5px;
                }
            }
        }
    }

    .red {
        background: #e84e4e;
    }

    .green {
        background: #24C537;
    }

    .yellow {
        background: #ffc107;
    }

    .green,
    .red,
    .yellow {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
}

.products #nprogress {
    .bar {
        height: 5px;
        background: #6C4AFF;
        top: unset;
        bottom: 0;
        .peg {
            box-shadow: none;
        }
    }
}

.dark #product {
    .ant-picker {
        border: 1px solid rgba(255, 255, 255, 0.25);
        color: rgba(255, 255, 255, 0.85);

        .ant-picker-input > input {
            color: rgba(255, 255, 255, 0.85); 
        }

        svg {
            color: rgba(255, 255, 255, 0.85);
        }

        .ant-picker-input:hover {
            svg {
                background-color: #242434;
            }
        }
    }

    .ant-picker-dropdown {
        .ant-picker-panel-container {
            background: #242434;
            box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

            .ant-picker-panel {
                border: none;
            }

            .ant-picker-header {
                border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                button {
                    color: rgba(255, 255, 255, 0.25);
                }
                .ant-picker-header-view button {
                    color: rgba(255, 255, 255, 0.85);
                }
            }
            .ant-picker-footer {
                border-top: 1px solid rgba(255, 255, 255, 0.25);
            }
            .ant-picker-cell {
                color: rgba(255, 255, 255, 0.25);
            }
            .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
            .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
                background: rgba(108, 74, 255, 0.50);
            }
            .ant-picker-content th,
            .ant-picker-cell-in-view {
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }
}
@primary-color: #6c4aff;