.home {
    background-color: #1B1A24;

    .banner {
        height: 880px;
        background-image:url('../../asset/home/banner.png');
        background-position: center top;
        background-size: auto 100%;
        background-repeat: no-repeat;
        margin-top: -86px;
        overflow: hidden;
        
        .container {
            -webkit-animation-name: fadeIn;
            -webkit-animation-duration: 2s;
            -webkit-animation-delay: 0s;
        }

        .title {
            margin-top: 270px;
            font-size: 70px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 100px;
        }

        .sub-title {
            width: 540px;
            margin-top: 15px;
            line-height: 24px;
            font-size: 18px;
        }

        .button {
            padding: 0 45px;
            height: 60px;
            background: #6C4AFF;
            border-radius: 4px;
            font-size: 18px;
            color: #FFFFFF;
            border: none;
            margin-top: 145px;

            &:hover {
                opacity: 0.9;
            }
        }
    }

    .content {
        .title {
            font-size: 32px;
            font-weight: 800;
            color: #FFFFFF;
            text-align:  center;
            margin-bottom: 40px;
        }

        .sub-title {
            font-size: 20px;
            text-align:  center;
            // font-weight: 800;
            margin-bottom: 20px;
        }

        .badge-baselevel {
            position: absolute;
            top: -347px;
            left: -8px;
            top: 999999;
        }
    }

   
    .products-block {
        margin-top: 270px;
    }

    .tutorials-block {
        margin-top: 160px;
    }

    .comments-block {
        margin: 140px 0 220px;
    }

    #qualgo {
        margin-top: 20px;        

        .title {
            margin-bottom: 20px;
        }

        .img-perf, .img-table {
            margin-bottom: 30px;
        }
       
        .ant-table {
            // background-color: rgba(0, 0, 0, 0.85);
            background-color: transparent;
            color: white;
            margin-bottom: 40px;
        }
        .ant-table-title {
            // color: #6C4AFF;
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            background-color: #1B1A24;
        }

        .ant-table-thead .ant-table-cell{
            background-color: #242434;
            color: white;
            font-weight: bold;
            font-size: 16px;
        }

        .ant-table-cell {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        }

        .ant-table-cell-row-hover {
            background-color: #242434 !important;
        }

        // .echarts-for-react {
        //     background-color: white;
        // }

        .return-section {
            h2 {
                font-size: 28px;
                text-align: center;
                font-weight: bold;
                color: white;
            }

            .ant-table {
                // color: black;
                font-size: 18px;
                // background: transparent;

                td {
                    width: 130px;
                    text-align: center;
                }
                th {
                    background-color: #242434;
                    text-align: center;
                    // border-top: 1px solid gray;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                }
            }
        }
    }

    .sets-block {
        padding: 80px 0 90px;
        background: #0E0C13;

        .title {
            font-size: 32px;
            font-weight: 800;
            color: #FFFFFF;
            text-align:  center;
        } 

    }    
}
@primary-color: #6c4aff;