.button-cancel, .button-confirm {
    &:focus {
        background: #6C4AFF;
        color: white;
    }

    background: #6C4AFF;
    margin-right: 10px;
    border: none;
    color: white;
}

.dropdown-trade {
    padding: 10px;
    label {
        display: block;
    }
    .input-simple {
        width: 105px;
    }
    .dropdown-splitter {
        padding: 5px;
    }
}

.dropdown-special {
    padding: 10px;
    h4, label, .ant-checkbox-disabled + span {
        color: white;
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: #6c4aff;
        border-color: #6c4aff !important;
    }
}

.theme-light {
    h4, label, .ant-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.85);
    }
}

.optiondata {
    .hide {
        visibility: hidden
    }
    
    #product-header {
        height: 250px;
        margin-bottom: 20px;

        .form-subTitle {
            margin-bottom: 8px;
        }

        // date picker
        .ant-picker-input {
            width: 160px;
            margin-right: 25px;
        }

        .select-button-frequency {
            margin-left: 25px;
        }

         // ticker
        .content-item-ticker .ant-input {
            // width: 120px;
        }

         // select day/week
         .content-item-frequency {
            .select-button {
                width: 500px;
                // margin-right: 20px;
            }
        }

        // .form-content {
        //     justify-content: end;
        // }

        .form-newFilter {
            margin-top: 10px;

            .ant-select-selector {
                min-height: 36px;
            }

            .select-simple {
                width: 120px;
            }
            .select-multiple {
                width: 180px;
            }
            .select-compare {
                width: 120px;
            }

            .ant-select-selection-placeholder {
                color: black
            }

            .select-multiple-container {
                position: relative;
            }

            .select-multiple-arrow {
                position: absolute;
                right: -10px;
                top: 12px;
                font-size: 12px;
                color: #d9d9d9;
            }
        }


        // old, TODO: remove 
        .form-moreFilter {
            border-top: 1px solid grey;
            margin-top: 10px;

            h3 {
                font-weight: bold;
            }

            // td {
            //     padding-left: 15px;
            // }

            .ant-select {
                margin: 10px;
            }

            .select-simple {
                width: 120px;
            }

            .select-compare {
                width: 120px;
            }

            .ant-picker {
                width: 150px;
            }

            .input-simple {
                width: 120px;
            }
        }
       

        .button {
            width: 120px;
        }      

        .select-button {
            width: calc(15%);
            cursor: pointer;            
        }

        .content-item-date {
            // width: 20%;

            .ant-input {
                width: calc(100% - 50px);
            }
        }

        .content-item-ticker {
            // width: 20%;
            // margin-right: 10px;

            .label {
                position: relative;                
            }

            .ant-input {
                max-height: 38px !important;
                min-height: 38px !important;
                height: 38px !important;
                width: calc(100% - 60px);
                padding: 0 10px;
            }
        }
    }    
    
    .product-result {
        .ant-tabs-content {
            padding-left: 20px;
        }
        .label {
            margin-right: 10px;
        }
        .table-header {
            margin-bottom: 20px;
        }

        .table-input {
            margin-bottom: 10px;
        }

        .product-result-search {
            width: 200px;
            height: 36px;
            margin-left: 10px;
        }
        .ant-table-content {
            .sell {
                color: #e84e4e;
                font-weight: bold;
            }
            .buy {
                color: #24c537;
                font-weight: bold;
            }
        }        
    }
}

.theme-dark {
    .optiondata {
        .ant-select-selector {
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: rgba(255, 255, 255, 0.85);
            background-color: transparent;
        }
    
        .ant-select-selection-placeholder {
            color: rgba(255, 255, 255, 0.85) !important;
        }
    
        .ant-select-selection-item-content {
            color: black;
        }

        .ant-select-selection-item-remove .anticon-close svg {
            color: black !important;
        }
        
    }

    .ant-select-dropdown {
        color: rgba(255, 255, 255, 0.85);
        background-color: #242434;
        border: 1px solid rgba(255, 255, 255, 0.25);

        .ant-select-item {
            color: rgba(255, 255, 255, 0.85);
        }
        .ant-select-item-option-active {
            background-color: rgba(108, 74, 255, 0.15)
        }

        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: transparent;
        } 

        .ant-input-number {
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: rgba(255, 255, 255, 0.85);
            background-color: transparent;

        }
    }
}

@primary-color: #6c4aff;