.sets-block-content {
    overflow: hidden;
    padding-top: 40px;

    .hide {
        display: none !important;
    }

    .payment-mode {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 50px;
        text-align: left;

        .badge-payment {
            position: absolute;
            left: 128px;
            width: 70px;
            top: -18px;
        }

        span:not(.ant-ribbon-text) {
            display: inline-block;
            width: 95px;
            line-height: 36px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            border: 1px solid rgba(255, 255, 255, 0.25);
            text-align: center;
            cursor: pointer;

            &:hover {
                background: rgba(108, 74, 255, 0.15);
                border-color: #6C4AFF;
                color: #fff;
            }
        }

        .active {
            background: rgba(108, 74, 255, 0.15);
            border-color: #6C4AFF !important;
            color: #fff;
        }

        span:not(.ant-ribbon-text):first-child {
            border-radius: 2px 0px 0px 2px;
            border-right: 1px solid #6C4AFF;
        }

        // span:nth-child(2) {
        //     border-radius: 0px 2px 2px 0px;
        //     border-left: none;
        // }
    }

    .container {
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: space-between;
        height: 676px;
        margin-bottom: 60px !important;

        .icon-algo {
            filter: saturate(5); 
            width: 32px !important;
        }

        .free {
            .badge-baselevel {
                position: absolute;
                top: -30px;
                left: -31px;

            }
        }

        .set {
            // 3 set
            // width: 387px; 
            // 4 set
            width: 290px; 
            height: 680px;

            background: #1B1923;
            border-radius: 6px;
            padding: 40px 20px 170px;
            position: relative;
            transition: all .4s ease;     

            .ant-ribbon-text {
                font-size: 13px;
                font-weight: bold;
            }
            
            .badge-option-trade {
                position: absolute;
                top: 206px;
                left: -31px;
            }

            .badge-optiondata {
                position: absolute;
                top: 246px;
                left: -31px;
            }

            .badge-unusalflow {
                position: absolute;
                top: 126px;
                left: -31px;
            }

            .badge-qualgo {
                position: absolute;
                top: 69px;
                left: -31px;
            }

            .description div:before {
                content: '\2713';
                display: inline-block;
                color: white;
                padding: 0 6px 0 0;
            }

            .title {
                margin: 0;
                height: 28px;
                line-height: 28px;

                span {
                    font-size: 24px;
                    font-weight: 800;
                    color: #FFFFFF;
                    line-height: 24px;
                }

                img {
                    width: 28px;
                    margin-right: 5px;
                }
            }

            .price {
                font-size: 72px;
                font-weight: bold;
                text-align: center;
                height: 80px;
                line-height: 80px;
                margin-top: 40px;

                span:first-child {
                    font-size: 32px;
                    font-weight: 800;
                }
                span:last-child {
                    font-size: 28px;
                    font-weight: 800;
                }
            }

            .total-price {
                min-height: 20px;
                font-size: 14px;
                color: #8669FF;
                // text-align: center;

                span:nth-child(2) {
                    font-weight: 800;
                }
                span:last-child {
                    margin-left: 4px;
                    text-decoration: line-through;
                }
            }

            .no-price {
                font-size: 36px;
                font-weight: 800;
                color: rgba(255, 255, 255, 0.85);
                text-align: center;
                height: 50px;
                margin-top: 70px;
                height: 80px;
            }

            .button {
                width: 250px;
                height: 52px;
                border-radius: 4px;
                border: 1px solid #6C4AFF;
                color: #fff;
                font-size: 18px;
                background: none;
                position: absolute;
                bottom: 50px;
            }

            .button-text {
                line-height: 52px;
                color: #fff;
                font-size: 18px;
                position: absolute;
                bottom: 50px;
                left: 0;
                right: 0;
                text-align: center;
            }

            &:hover {
                border-top: 5px solid #6C4AFF;
                height: 715px;
                padding-top: 60px;
                background: #242434;
                box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
                transform: translateY(-36px);

                .button {
                    background: #6C4AFF;
                }
            }
        }

        .disable {
            .title span{
                color: rgba(255, 255, 255, 0.65);
            }

            .button {
                border: none;
                color: rgba(255, 255, 255, 0.85);
                background: none !important;
            }
        }
    }

    .set.diamond {
        .button {
            left: 20px;
        }
        .description {
            margin-top: 170px;
            font-size: 15px;
        }
    }

    // .diamond {        
    //     width: 1200px;
    //     background: #1B1923;
    //     border-radius: 6px;
    //     border: 1px solid #48474F;
    //     padding: 60px 40px;
    //     margin: 50px auto 0;
    //     display: -webkit-flex; /* Safari */
    //     display: flex;
    //     align-items: center;

    //     .title {
    //         font-size: 24px;
    //         font-weight: 800;

    //         img {
    //             width: 26px;
    //             margin-right: 6px;
    //         }
    //     }

    //     .description {
    //         width: 512px;
    //         font-size: 16px;
    //         color: rgba(255, 255, 255, 0.85);
    //         line-height: 24px;
    //         margin-left: 40px;
    //     }

        .button {
            width: 160px;
            height: 52px;
            border-radius: 4px;
            border: 1px solid #6C4AFF;
            font-size: 18px;
            font-weight: 600;
            color: #fff;
            background: none;
            margin-left: 0px;
        }
    }

body.en {
    .set {
        height: 785px !important;
    }

    .badge-unusalflow {
        top: 150px !important;
    }

    .badge-optiondata {
        top: 275px !important;
    }

    .badge-qualgo {
        top: 74px !important;
    }
}

@primary-color: #6c4aff;