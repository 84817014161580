// mobile 
.sign-in-layout {
    .left {        
        display: none;
    }
    .right {
        width: 100%;    
        background-color: #fff;
        padding: 95px 60px;

        .back-btn a {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            text-decoration: none;

            &:hover {
                color: #6c4aff;
            }
        }

        .logo {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);

            img {
                display: block;
                margin-top: 65px;
                width: 280px;
                margin-bottom: 10px;
            }
        }

    }
}

// desktop
@media only screen and (min-width: 768px) {
    .sign-in-layout {
        display: flex;
        flex-direction: row;

        .left {
            display: block;
            width: 58%;
            min-height: 100vh;
            background-image:url('../../asset/layout/right-image.png');
            background-size: cover;
            background-position: right center;
            background-repeat: no-repeat;
        }

        .right {
            width: 42%;
                                
            .content {
                margin-top: 70px;
            }
        }
    }
}

@primary-color: #6c4aff;