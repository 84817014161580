.tutorials-block-content {
    overflow: hidden;

    .video-list {
        float: right;
        width: 380px;
        height: 462px;
        background: #171523;
        border-radius: 6px;
        border: 1px solid #48474F;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .item {
            width: 100%;
            height: 63px;
            border-radius: 4px;
            overflow: hidden;
            margin: 5px;
            padding: 8px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: normal;
            transition: all ease-in 0.2s 0s;
       
            img {
                width: 10px;
                margin-right: 10px;
            }

            .title {
                margin: 0;
                font-size: 16px;
                text-align: left;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: unset;
            }
        }

        .active,
        .item:hover {
            background: #242434;

            .title {
                font-weight: 800;
            }
        }
    }


    .video {
        float: left;

        iframe {
            width: 800px;
            height: 462px;
        }
    }
}
@primary-color: #6c4aff;