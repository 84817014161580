.light .ant-layout-content {
    background-color: #1b1923 !important;
}

.account {
    .banner {
        height: 680px;
        background-color: #1F2220;
        background-image:url('../../asset/aboutUs/banner.png');
        background-position: center top;
        background-size: 1960px auto;
        background-repeat: no-repeat;

        .container {
            .title {
                padding-top: 150px;
                font-size: 72px;
                font-weight: 600;
            }

            .description {
                width: 510px;
                line-height: 22px;
                font-size: 16px;
                margin-top: 30px;

                .text {
                    margin-bottom: 20px;
                    word-wrap: break-word;
                }
            }

            .ant-row {
                margin-bottom: 10px;
            }
        }
        
    }

    .button {
        padding: 0 45px;
        height: 40px;
        background: #6C4AFF;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        border: none;
        margin-top: 15px;

        &:hover {
            opacity: 0.9;
        }
    }
    
}
@primary-color: #6c4aff;