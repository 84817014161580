.multi-flow {
    .content-item {
        display: flex;
        align-items: center;
        width: 41%;
        padding-right: 10px;
    }

    .ant-input {
        width: calc(100% - 80px);
    }

    .ant-input {
        line-height: unset !important;
        min-height: 36px !important;
    }

    .ant-picker {
        width: calc(100% - 50px);
    }

    .button {
        width: 18%;
    }

    .table {
        margin-top: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    } 
}
@primary-color: #6c4aff;