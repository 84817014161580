.ant-menu-vertical .ant-ribbon {
    display: none;
}

.ant-layout {
    background-color: #1B1923;
    color: #fff;
    
    .banner-en {
        margin: 15px 30px;
    }

    .ant-layout-header {
        height: 86px;
        line-height: 86px;
        padding: 0;
        background-color: rgba(17, 15, 27, 0.8);
        backdrop-filter: blur(8px);
        z-index: 3;

        .logo {
            width: 236px;
            padding-left: 30px;
        }

        .ant-menu {
            background-color: inherit;
            border: none;
            color: #fff;
            font-size: 16px;            

            .ant-menu-item {
                padding: 0 24px;

                // &::after {
                //     bottom: 26px;
                // }

                &:not(.link-start):hover {
                    color: #fff;
                    font-weight: bold;

                    &::after {
                        border-bottom: 2px solid #6c4aff;               
                    }
                }

                &:active {
                    background: unset;
                }

                // &.start:hover {
                //     &::after {
                //         border-bottom: 0px;
                //     }
                // }

                &.link-start .ant-menu-title-content {
                    padding: 7px 25px;
                    height: 40px;
                    background: #6C4AFF;
                    border-radius: 4px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: none;
                    margin-top: 15px;
                    
            
                    &:hover {
                        opacity: 0.9;
                    }

                }

                &.mustread .ant-ribbon {
                    left: -16px;
                }
            }

            .ant-menu-item-selected {
                color: #fff;
                font-weight: bold;

                &::after {
                    border-bottom: 2px solid #6c4aff;
                }                
            }

            .ant-menu-item-active {
                &::after {
                    border-bottom: 2px solid #6c4aff;
                } 
            }
            .ant-menu-item:focus-visible, .ant-menu-submenu-title:focus-visible {
                box-shadow: none
            }
        }

        .rightContent {
            text-align: center;
            // padding-right: 2px;

            .discord-btn {
                height: 27px;
                line-height: 27px;
                margin-right: 10px;
                background: #6c4aff;
                color: #fff;
                border: none;
            }

            .ant-btn {
                font-size: 16px;
            }

            .themeChange {
                margin-right: 10px;
                color: #fff;
            }

            .theme {                
                position: relative;
                top: 5px;
            }

            .language {
                color: #fff;
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.65);
                padding: 0 13px;
                margin-right: 10px;
                line-height: 24px;
                height: 26px;

                &:hover {
                    border: 1px solid #fff;
                }
            }

            .sign-in-btn {
                height: 36px;
                background-color: #6c4aff;
                border-radius: 4px;
                border: none;
                padding: 0 25px;
                margin-right: 15px;

                &:hover {
                    opacity: 0.9;
                }
            }

            .ant-dropdown-link {
                .level {
                    display: inline-block;
                    color: rgba(255, 255, 255, 0.85);
                    font-size: 16px;
                    font-weight: 800;

                    img {
                        height: 25px;
                        margin-right: 5px;
                    }
                }

                .anticon-down {
                    margin-left: 5px;
                    font-size: 12px;

                    svg {
                        color: rgba(255, 255, 255, 0.85);
                    }
                }

                &:hover {
                    .level {
                        color: #fff;
                    }

                    .anticon-down svg {
                        color: #fff;
                    }
                }
            }
        }

        .ant-menu-submenu-active {
            &:hover {
                color: #6c4aff;

                &::after {
                    border-bottom: 2px solid #6c4aff;
                }
            } 
        }
        .ant-menu-submenu-open,
        .ant-menu-submenu-selected {
            color: #6c4aff;

            &::after {
                border-bottom: 2px solid #6c4aff;
            }
        }

        .ant-menu-submenu-title:hover {
            color: #6c4aff;
        }
    }

    .ant-layout-content {
        background-color: #1B1A24;
        padding-top: 86px;

        .container {
            width: 1200px;
            margin: 0 auto;
        }
    }

    .ant-layout-footer {
        background-color: #1B1A24;
        color: #fff;
        text-align: center;
        padding: 90px 0 60px;

        .footer-container {
            width: 1200px;
            margin: 0 auto;
        }

        img {
            width: 236px;
        }

        .line {
            height: 1px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.25);
            margin-top: 30px;
        }

        .ant-btn-link {
            color: #fff;
            padding: 30px 55px;
            font-size: 16px;
            height: 85px;
            border: none;

            &:hover {
                color: #6c4aff;
            }
        }

        .text {
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.65);;
        }
    }
}

.ant-menu-horizontal .ant-menu-submenu {
    .ant-menu-item-active {
        &:hover {
            color: #6c4aff;
        } 
    }

    .ant-menu-item-selected {
        color: #6c4aff;
    }

    &::after {
        bottom: 26px;
    }
}

.ant-layout .ant-layout-header .ant-menu-submenu-open::after, .ant-layout .ant-layout-header .ant-menu-submenu-selected::after {
    bottom: 26px;
}

.light .ant-layout-header {
    background-color: rgba(17, 15, 27);
    backdrop-filter: unset;
}

.ant-dropdown-menu {
    margin-top: 25px;

    span {
        line-height: 30px;
    }

    li:hover  {
        background: unset;
        span {
            color: #6c4aff;
        }
    }
} 

.light .ant-layout-content {
    background: #EFEFF6;
}


// mobile 
@media only screen and (max-width: 769px) {
    .ant-layout {
        .ant-layout-header {
            .menuContainer {
                max-width:69%;
                flex: 0 0 69%;
            }
        }
    }
}

// smaller screen
@media only screen and (max-width: 1550px) {
    .ant-layout {
        .ant-layout-header {
            .logoContainer {
                overflow: hidden;
                max-width: 72px;
            }   

            .signinContainer {
                max-width: 36%;
                // margin-left: 0 !important;
                flex: none;
                // flex: 0 0 35%;
            }                  
        }
    }
}
@media only screen and (max-width: 1550px) and (min-width: 1175px) {
    .ant-layout {
        .ant-layout-header {        
            .menuContainer {
                // max-width: 64%;
                flex: 0 0 64%;
            }

            .signinContainer {
                margin-left: auto;
            }
        }
    }
}

// smaller screen
@media only screen and (max-width: 1015px) {
    .ant-layout {
        .ant-layout-header {
            .signinContainer {
                min-width: 300px;
                max-width: 300px;
            }                       
        }
    }
}
// super small screen for desktop only
@media only screen and (max-width: 815px) {
    .desktop .ant-layout {
        .ant-layout-header {
            .menuContainer {
                max-width: 40%;
                flex: 0 0 40%;
            }                
        }
    }
}

.mobile {
    .signinContainer {
        position: absolute;
        right: 0;
    }

    .themeChange {
        margin-right: 0px !important;   
    }
}

@primary-color: #6c4aff;