
.forgot {
    .title {
        font-size: 24px;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 25px;
    }

    .input {
        width: 380px;
        height: 40px;

        &:hover{
            border-color: #6c4aff;
        }
    }

    .sent-message {
        font-size: 16px;
        color: rgba(246, 2, 2, 0.85);
    }

    .ant-form {
        overflow: hidden;
    }

    .reset-btn {
        width: 380px;
        height: 52px;
        background: #6C4AFF;
        border-radius: 4px;
        border: none;
        font-size: 18px;
        margin-top: 4px;

        &:hover {
            opacity: 0.95;
        }
    }
}
@primary-color: #6c4aff;