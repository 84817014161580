.gex {
    .content-item {
        width: 41%;
        padding-right: 10px;
    }

    .ant-input {
        width: calc(100% - 80px);
    }

    .ant-picker {
        width: calc(100% - 50px);
    }

    .button {
        width: 18%;
    }

    .chart-header {
        display: flex;
        justify-content: space-between;
        
        .chart-button {
            span {
                display: inline-block;
                width: 106px;
                line-height: 30px;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 0.25);
                cursor: pointer;

                &:nth-child(1) {
                    border-radius: 2px 0 0 2px; 
                }

                &:nth-child(3) {
                    border-radius: 0 2px 2px 0; 
                }
            }

            .active {
                background: rgba(108, 74, 255, 0.15);
                border-color: #6C4AFF;
                color: #fff;
            }
        }

        .chart-select {
            .ant-select {
                width: 145px;
                
                .ant-select-selector {
                    background-color: unset;
                }
            }
        }
    }
}

.light .gex .chart-header .chart-button {
    span {
        border: 1px solid rgba(0, 0, 0, 0.25);
    }
    
    .active {
        color: rgba(0, 0, 0, 0.85);
        border: 1px solid #6C4AFF;
    }
}
@primary-color: #6c4aff;