.empty {
    text-align: center;
    padding: 100px 0;

    img {
        width: 150px;
    }

    .message {
        font-size: 18px;
        font-weight: 800;
        margin-top: 25px;
    }
}

.light .empty {
    img {
        opacity: 0.9;
    }
}
@primary-color: #6c4aff;