
.sign-in {
    .title,
    .title-join {
        font-size: 24px;
        font-weight: 800;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 25px;
    }

    .title-join {
        margin-top: 30px;
    }

    .input {
        width: 380px;
        height: 40px;

        &:hover{
            border-color: #6c4aff;
        }
    }

    .sign-in-btn {
        width: 380px;
        height: 52px;
        background: #6C4AFF;
        border-radius: 4px;
        border: none;
        font-size: 18px;

        &:hover {
            opacity: 0.95;
        }
    }

    .error-message {
        font-size: 16px;
        color: rgba(246, 2, 2, 0.85);
    }

    .ant-form {
        width: 380px;
        overflow: hidden;

        .ant-checkbox {
        color: rgba(0, 0, 0, 0.65);
        }

        .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #6c4aff;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #6c4aff;
            border-color: #6c4aff;
        }

        .ant-checkbox-checked::after {
            border: 1px solid #6c4aff;
        }

        .forgot-password-link {
            float: right;
            color: #6c4aff;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .signUp-Btn {
        width: 380px;
        height: 52px;
        border-radius: 4px;
        border: 1px solid #6C4AFF;
        font-size: 18px;
        color: #6C4AFF;

        &:hover {
            opacity: 0.8;
            border: 1px solid rgba(108, 74, 255, 0.8);
        }
    }
}
@primary-color: #6c4aff;