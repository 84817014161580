.qu100 {
    .content-item {
        display: flex;
        align-items: center;
        padding-right: 10px;
        width: 44.3%;
    }

    .select-button {
        width: calc(35% - 25px) !important;
    }

    .ant-picker {
        width: calc(100% - 50px);
    }

    .button {
        width: 15.7%;
    }

    .tooltip-title {
        font-size: 16px;
        font-weight: 800;
        color: rgba(255, 255, 255, 0.85);
        margin-bottom: 20px;
    }


    .tooltip {
        padding: 20px;

        thead {
            text-align: left; 
        }

        td {
            font-size: 14px;
            min-width: 65px;
            padding: 4px 0;
        }
    }

    .purple {
        cursor: pointer;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .table-title {
            margin-bottom: 0;
        }

        .table-input {
            display: flex;
            align-items: center;

            span {
                font-size: 16px;
                margin-right: 7px;
                color: rgba(255, 255, 255, 0.85);
            }

            .ant-input {
                width: 200px;
                height: 36px;
                background: unset;
                border: 1px solid rgba(255, 255, 255, 0.25);
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }

    .table,
    .chart-treemap {
        .red {
            display: unset;
            background: unset;
            color: #e84e4e;
            margin-right: 4px;
        }
    
        .green {
            display: unset;
            background: unset;
            color: #24C537;
            margin-right: 4px;
        }
        
    }
}

.light .qu100 {
    .table-input {
        span {
            color: rgba(0, 0, 0, 0.85);
        }

        .ant-input {
            border: 1px solid rgba(0, 0, 0, 0.25);
            color: rgba(0, 0, 0, 0.85);
        }
    }
}
@primary-color: #6c4aff;